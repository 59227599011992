import React from 'react';
import Container from 'react-bootstrap/Container';
// import HomeBanner from '../components/HomeBanner';
import HomeMain from '../components/HomeMain';
import FullSceneDigital from '../components/FullSceneDigital';
import HomeDownload from '../components/HomeDownload';
import HomeFeatures from '../components/HomeFeatures';
import HomeTool from '../components/HomeTool';
import MoreSolutions from '../components/MoreSolutions';
import HomeLogos from '../components/HomeLogos';
import Layout from '../components/Layout';
import ApplyButton from '../components/ApplyButton';
import HomeBannerWithVideo from '../components/HomeBannerWithVideo';
import RecommendedReading from '../components/RecommendedReading';

export default () => (
  <Layout current="home">
    <HomeBannerWithVideo />
    {/* <HomeBanner /> */}
    <HomeMain />
    <HomeFeatures />
    <FullSceneDigital />
    <HomeDownload />
    <HomeTool />
    <MoreSolutions />
    <HomeLogos />
    <div className="home-growth-topper">
      <Container>
        <h3>TOB企业不缺变革决心，缺的是基于业务出发的运营思维和工具</h3>
        <h3>客户不缺信息推送，缺的是真正为TA着想的每一次链接和服务</h3>
        <ApplyButton theme="black">免费体验DEMO</ApplyButton>
      </Container>
    </div>
    <RecommendedReading />
  </Layout>
);
