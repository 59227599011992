import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ColoredIcon from './ColoredIcon';

// TODO 内页增加后，参考 https://www.sensorsdata.cn/auto 实现 hover 效果
function VerticalBox({ img, title, description, iconIndex }) {
  return (
    <div className="vertical-box">
      {iconIndex ? <ColoredIcon iconIndex={iconIndex} /> : <Image src={img} alt="vertical" />}
      {title && <span className="title">{title}</span>}
      {description && <span className="description">{description}</span>}
    </div>
  );
}

VerticalBox.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  iconIndex: PropTypes.number,
};

export default VerticalBox;
