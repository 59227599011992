import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Tabs, Tab, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useSearch } from '../utils/common';
import TitleWithBg from './TitleWithBg';
import SceneDigital1 from '../images/scene-digital-1.png';
import SceneDigital2 from '../images/scene-digital-2.png';
import SceneDigital3 from '../images/scene-digital-3.png';
import SceneDigital4 from '../images/scene-digital-4.png';

function LeftBorderItem({ title, description }) {
  return (
    <div className="left-border-item">
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  );
}

LeftBorderItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

let timer = null;

export default function FullSceneDigital() {
  const [activeKey, setActiveKey] = useState('1');
  const [tabsDirection, setTabsDirection] = useState('right');
  const search = useSearch();

  const onTabsChange = (key) => {
    if (activeKey === key) return;
    if (parseInt(activeKey) > parseInt(key)) {
      setTabsDirection('left');
    } else {
      setTabsDirection('right');
    }
    setActiveKey(key);
  };

  // 自动循环+点击切换
  useEffect(() => {
    timer = setInterval(() => {
      setActiveKey((_current) => {
        const newKey = parseInt(_current, 10) + 1;
        return newKey > 4 ? '1' : `${newKey}`;
      });
    }, 1000 * 5);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  // TODO 四张主图如果还要显示的话，改成png，否则背景变灰之后不好看

  return (
    <section id="full-scene-digital" className="full-scene-digital">
      <Container className={`towards-${tabsDirection}`}>
        <TitleWithBg title="全场景数字化赋能" />
        <p className="digital-description">
          荟聚适用于从获取客户到留住客户，从活动执行到企业整体数字化转型的各种场景
        </p>
        <div className="tabs-custom-wrapper">
          <Tabs
            activeKey={activeKey}
            className={`scene-tabs towords-${tabsDirection}`}
            onSelect={onTabsChange}
          >
            <Tab eventKey="1" title="全渠道引流获客">
              <Row className="scene-tab-content">
                <Col
                  lg={{ span: 6, order: 1 }}
                  md={{ span: 12, order: 1 }}
                  sm={{ span: 12, order: 2 }}
                  xs={{ span: 12, order: 2 }}
                >
                  <p className="content-title">全渠道引流获客</p>
                  <p className="content-description">
                    整合线上线下全渠道营销触点，识别跨平台用户身份，整合用户行为轨迹，将用户全渠道、全路径数据自动汇总到统一的客户档案。
                  </p>
                  <div>
                    <LeftBorderItem
                      title="获客渠道分散"
                      description="渠道分散，数据不统一，无法有效管理，重复及无效数据混杂"
                    />
                    <LeftBorderItem
                      title="营销场景割裂"
                      description="营销场景割裂且生硬，无法有效吸引和打动客户"
                    />
                    <LeftBorderItem
                      title="用户画像缺失"
                      description="同个客户分散在不同的营销渠道没有做统一整理和合并识别"
                    />
                  </div>
                  <div className="bottom-link">
                    <Link to={`/channels/${search}`}>
                      <span>了解更多</span>
                      <div className="common-arrow-right" />
                    </Link>
                  </div>
                </Col>
                <Col
                  lg={{ span: 6, order: 2 }}
                  md={{ span: 12, order: 2 }}
                  sm={{ span: 12, order: 1 }}
                  xs={{ span: 12, order: 1 }}
                  className="tab-image"
                >
                  <Image src={SceneDigital1} alt="全渠道引流获客" />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="2" title="私域流量运营">
              <Row className="scene-tab-content">
                <Col
                  lg={{ span: 6, order: 1 }}
                  md={{ span: 12, order: 1 }}
                  sm={{ span: 12, order: 2 }}
                  xs={{ span: 12, order: 2 }}
                >
                  <p className="content-title">私域流量运营</p>
                  <p className="content-description">
                    将全渠道用户统一至微信生态( 微信公众号 + 企业微信
                    )进行流量打造，用户进行培育及再利用，实现低成本获客转化。
                  </p>
                  <div>
                    <LeftBorderItem
                      title="公域流量红利丧尽"
                      description="流量红利时代结束，广告获客成本逐年增加，转化率越来越差"
                    />
                    <LeftBorderItem
                      title="线索缺乏维护再利用"
                      description="大量线索沉寂流失，销售跟进后没有有效手段长期维护客户"
                    />
                    <LeftBorderItem
                      title="用户画像单薄"
                      description="传统触点互动费用高频次低，导致客户行为和画像收集困难"
                    />
                  </div>
                  <div className="bottom-link">
                    <Link to={`/privacy/${search}`}>
                      <span>了解更多</span>
                      <div className="common-arrow-right" />
                    </Link>
                  </div>
                </Col>
                <Col
                  lg={{ span: 6, order: 2 }}
                  md={{ span: 12, order: 2 }}
                  sm={{ span: 12, order: 1 }}
                  xs={{ span: 12, order: 1 }}
                  className="tab-image"
                >
                  <Image src={SceneDigital2} alt="私域流量运营" />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="3" title="自动化线索培育">
              <Row className="scene-tab-content">
                <Col
                  lg={{ span: 6, order: 1 }}
                  md={{ span: 12, order: 1 }}
                  sm={{ span: 12, order: 2 }}
                  xs={{ span: 12, order: 2 }}
                >
                  <p className="content-title">自动化线索培育</p>
                  <p className="content-description">
                    根据多种营销场景，通过自动化流程提升线索转化率，个性化触达潜在客户，预测客户行为，发现优质商机。
                  </p>
                  <div>
                    <LeftBorderItem
                      title="长尾线索严重浪费"
                      description="对短期低价值线索缺少持续沟通和培养，线索流失率高"
                    />
                    <LeftBorderItem
                      title="无法有效激活沉寂线索"
                      description="停留在千人一面的线索培育，无法提供个性化服务"
                    />
                    <LeftBorderItem
                      title="无法及时发现高质量线索"
                      description="无法根据实时客户行为洞察客户意向，发现优质商机"
                    />
                  </div>
                  <div className="bottom-link">
                    <Link to={`/autoclue/${search}`}>
                      <span>了解更多</span>
                      <div className="common-arrow-right" />
                    </Link>
                  </div>
                </Col>
                <Col
                  lg={{ span: 6, order: 2 }}
                  md={{ span: 12, order: 2 }}
                  sm={{ span: 12, order: 1 }}
                  xs={{ span: 12, order: 1 }}
                  className="tab-image"
                >
                  <Image src={SceneDigital3} alt="全渠道引流获客" />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="4" title="市场销售协同">
              <Row className="scene-tab-content">
                <Col
                  lg={{ span: 6, order: 1 }}
                  md={{ span: 12, order: 1 }}
                  sm={{ span: 12, order: 2 }}
                  xs={{ span: 12, order: 2 }}
                >
                  <p className="content-title">市场销售协同</p>
                  <p className="content-description">
                    根据业务规则进行线索自动打分和线索智能分配，销售实时获得最新客户行为轨迹，有效辅助商机跟进，完成「营」「销」一体化协同。
                  </p>
                  <div>
                    <LeftBorderItem
                      title="线索管理流程不清晰"
                      description="缺乏对MQL/SQL的定义和管理，导致市场销售协作效率低"
                    />
                    <LeftBorderItem
                      title="分配线索效率低"
                      description="第一手高质量线索难以马上推给销售，销售也难以即时跟进"
                    />
                    <LeftBorderItem
                      title="SDR/销售互动方式单一"
                      description="SDR/销售跟进线索时缺乏个性化内容互动，展示信息方式单调"
                    />
                  </div>
                  <div className="bottom-link">
                    <Link to={`/collaboration/${search}`}>
                      <span>了解更多</span>
                      <div className="common-arrow-right" />
                    </Link>
                  </div>
                </Col>
                <Col
                  lg={{ span: 6, order: 2 }}
                  md={{ span: 12, order: 2 }}
                  sm={{ span: 12, order: 1 }}
                  xs={{ span: 12, order: 1 }}
                  className="tab-image"
                >
                  <Image src={SceneDigital4} alt="全渠道引流获客" />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </section>
  );
}
