import React, { useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import handleViewport from 'react-in-viewport';
import ApplyButton from './ApplyButton';
import BannerVideoPoster from '../images/banner-video-poster-4.jpg';
// import './HomeBannerWithVideo.scss';

const desc = `升级市场职能，带动企业增长`;

const Block = (props) => {
  const { inViewport, forwardedRef } = props;
  return <div ref={forwardedRef} style={{ width: '1px', backgroundColor: 'rgba(0,0,0,0)' }}></div>;
};

const ViewportBlock = handleViewport(Block);

function VideoView(props) {
  const videoRef = useRef(null);
  const [taggle, setTaggle] = useState(false);

  return (
    <>
      <Col xl={9} lg={6} md={6} sm={12} className="home-banner-image" style={{ zIndex: 1 }}>
        <div className="video-view-container">
          <div className={taggle ? 'video-view-min' : 'video-view-100'}>
            {!taggle ? null : (
              <div
                className="video-view-close"
                onClick={() => {
                  setTaggle(!taggle);
                }}
              >
                关闭
              </div>
            )}
            <video
              ref={videoRef}
              controls
              loop
              width="100%"
              id="video-view"
              controlsList="nodownload"
              poster={BannerVideoPoster}
            >
              <source src="https://cltemplate.oss-cn-hangzhou.aliyuncs.com/template/jike/video/official-20220223.mp4" />
            </video>
          </div>
        </div>
        <ViewportBlock
          onEnterViewport={() => {
            taggle !== false && setTaggle(false);
          }}
          onLeaveViewport={() => {
            taggle !== true && setTaggle(true);
          }}
        />
      </Col>
    </>
  );
}

function HomeBannerWithVideo(props) {
  return (
    <section id="banner" className="home-banner-section">
      <Container>
        <Row className="home-banner-container">
          <Col xl={3} lg={6} md={6} sm={12} className="home-banner-content">
            <h4 className="title">Marketing-led Growth</h4>
            <h2 className="video-view-container-h2">荟集线索</h2>
            <h2 className="video-view-container-h2">聚焦转化</h2>
            <p className="video-view-container-p">{desc}</p>
            <ApplyButton theme="black">免费体验DEMO</ApplyButton>
          </Col>
          <VideoView />
          <div className="group9 flex-col"></div>
        </Row>
      </Container>
    </section>
  );
}

export default HomeBannerWithVideo;
