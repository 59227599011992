import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import TitleWithBg from './TitleWithBg';
import ColoredIcon from './ColoredIcon';
import { useSearch } from '../utils/common';

function SolutionCard({
  title,
  iconIndex = 1,
  hoverIconIndex = 1,
  description,
  href = '',
  solutions = [],
}) {
  return (
    <Col lg={3} md={6} sm={12} xs={12} className="solution-col">
      <div className="solution-card">
        <div className="top-wrapper">
          <ColoredIcon iconIndex={iconIndex} size={60} hoverIconIndex={hoverIconIndex} />
          <div className="title-and-description">
            <p className="title">{title}</p>
            <p className="description">{description}</p>
          </div>
        </div>
        <ul className="solution-ul">
          {solutions.map((solution) => (
            <li key={solution}>{solution}</li>
          ))}
        </ul>
        {href ? (
          <div className="bottom-link">
            <Link to={href}>
              <span>了解更多</span>
              <div className="common-arrow-right" />
            </Link>
          </div>
        ) : (
          <div className="bottom-link">
            <a>敬请期待</a>
          </div>
        )}
      </div>
    </Col>
  );
}

SolutionCard.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  solutions: PropTypes.arrayOf(PropTypes.string),
  iconIndex: PropTypes.number,
  hoverIconIndex: PropTypes.number,
  href: PropTypes.string,
};

export default function MoreSolutions() {
  const search = useSearch();
  return (
    <section id="more-solutions" className="more-solutions">
      <Container>
        <TitleWithBg title="更多行业解决方案" />
        <Row className="solutions-row">
          <SolutionCard
            iconIndex={12}
            hoverIconIndex={16}
            title="工业制造行业"
            description="掌握线索全貌，提升营销效率"
            solutions={[
              '精准评估线索质量',
              '持续高效培育线索',
              '提升市场活动效率',
              '全面赋能销售/经销商',
            ]}
            href={`/manufacturing/${search}`}
          />
          <SolutionCard
            iconIndex={13}
            hoverIconIndex={17}
            title="高新软件行业"
            description="优化营销效果，加速线索转化"
            solutions={[
              '构建来源体系并标记',
              '刻画线索特征和指标',
              '自动化精细培育线索',
              '多维度分析营销效率',
            ]}
            href={`/software/${search}`}
          />
          <SolutionCard
            iconIndex={14}
            hoverIconIndex={18}
            title="企业服务行业"
            description="高效培育线索，促进商机成熟"
            solutions={[
              '依据画像评估客户喜好',
              '高效管理和宣发内容',
              '线索自动培育和筛选',
              '投放效果全链路分析',
            ]}
            href={`/enterprise-service/${search}`}
          />
          <SolutionCard
            iconIndex={15}
            hoverIconIndex={19}
            title="化工原材料行业"
            description="精细分层客群，营销精准覆盖"
            solutions={[
              '打通并整合电商数据',
              '精细化分层客户群体',
              '自动化实施差异化营销',
              '协同并赋能销售/经销商',
            ]}
            href={`/chemical-raw-materials/${search}`}
          />
        </Row>
      </Container>
    </section>
  );
}
