import React from 'react';
import { Container } from 'react-bootstrap';
import TitleWithBg from './TitleWithBg';
import HomeMainSvg from '../images/home-main-20220215.svg';

export default function HomeMain() {
  return (
    <section id="home-main" className="home-main">
      <Container>
        <TitleWithBg title="跨越数据到增长的鸿沟" />
        <p className="description">
          跨平台采集和整合各渠道营销数据，建立私域流量运营矩阵，通过360度线索画像以及自动化线索培育，
          帮助B2B企业提升不同获客渠道的转化ROI，且无缝衔接CRM等营销软件，打造企业营销闭环。
        </p>
        <img src={HomeMainSvg} alt="跨越数据到增长的鸿沟" />
      </Container>
    </section>
  );
}
