import React from 'react';
import { Container } from 'react-bootstrap';
import ApplyButton from './ApplyButton';

export default function HomeDownload() {
  return (
    <section id="home-download" className="home-download">
      <Container>
        <div className="download-content">
          <p className="title">《TOB数字营销场景解决方案》</p>
          <p className="description">
            8大场景，通过Martech技术连接数据与营销场景，让营收增长成为B2B营销首要目标
          </p>
          <ApplyButton theme="black">免费下载</ApplyButton>
        </div>
      </Container>
    </section>
  );
}
