import React from 'react';
import { Container, Carousel, Row, Col } from 'react-bootstrap';
import { useStaticQuery, graphql, Link } from 'gatsby';
const DEFAULT_COVER =
  'https://cltemplate.oss-cn-hangzhou.aliyuncs.com/template/jike/WX20220113-195216%402x.png';

function RecommendCard({ slug, frontmatter }) {
  const { title, description, cover, author, date, type } = frontmatter;
  return (
    <div className="rcr-card">
      <div>
        <div className="rcr-card-cover">
          <img src={cover || DEFAULT_COVER} alt="cover" />
        </div>
        <div className="rcr-card-title"><a style={{ color: '#262626' }} href={`/${type}/${slug}/`} target="_blank" rel="noreferrer">{title}</a></div>
        <div className="rcr-card-description">{description}</div>
        <div className="rcr-card-footer">
          <div className="rcr-card-footer-left">
            <div className="author">作者：{author}</div>
            <div className="date">{date}</div>
          </div>
          <a href={`/${type}/${slug}/`} target="_blank" rel="noreferrer">
            查看全文 &gt;
          </a>
        </div>
      </div>
    </div>
  );
}

function useLatestBlogs() {
  const { blogs, insights } = useStaticQuery(graphql`
    query latestBlogsQuery {
      blogs: allMdx(
        limit: 3
        filter: { frontmatter: { type: { eq: "blog" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            title
            type
            cover
            description
            author
            date
          }
          slug
          id
        }
      }
      insights: allMdx(
        limit: 3
        filter: { frontmatter: { type: { eq: "industryinsight" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            title
            type
            cover
            description
            author
            date
          }
          slug
          id
        }
      }
    }
  `);
  return { blogs: blogs.nodes, insights: insights.nodes };
}

export default function RecommendedReading() {
  const { blogs, insights } = useLatestBlogs();

  return (
    <section id="recommendedReading" className="home-recommendedReading">
      <Container>
        <div style={{ position: 'relative' }}>
          <div className="rcr-more">
            <Link to="/industryinsights/">
              <span>了解更多</span>
              <div className="common-arrow-right" />
            </Link>
          </div>
          <div className="rcr-head">
            <div className="title-wrapper title-bg">
              <div className="background-bar"></div>
              <h3>推荐阅读</h3>
            </div>
          </div>
          <Carousel controls={false} indicators={false} className="rcr-carousel">
            <Carousel.Item>
              <Row className="rcr-card-list">
                {blogs.map((blog) => (
                  <Col key={blog.id} lg={4} md={4} sm={6} xs={12} className="rcr-card-item">
                    <RecommendCard {...blog} />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row className="rcr-card-list">
                {insights.map((blog) => (
                  <Col key={blog.id} lg={4} md={4} sm={6} xs={12} className="rcr-card-item">
                    <RecommendCard {...blog} />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          </Carousel>
        </div>
      </Container>
    </section>
  );
}
