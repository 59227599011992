import React from 'react';
import { Container } from 'react-bootstrap';
import ImageText from './ImageText';
import Feature1 from '../images/home-feature-1-v3.svg';
import Feature2 from '../images/home-feature-2-v3.svg';
import Feature3 from '../images/home-feature-3-v3.svg';
import Feature4 from '../images/home-feature-4-v3.svg';

function HomeFeatures() {
  return (
    <section id="features" className="home-features">
      <ImageText
        position="right"
        bgColor="grey"
        image={Feature1}
        title="前所未有的看清客户"
        description="连接全渠道，并对各种客户数据做集中统一的管理，不仅仅提供大数据分析和可视化，还为B2B企业提供适合第一方独立运营的用户标签体系，同时和内容交互平台、营销自动化无缝衔接，实现从洞察到执行的完整数据变现能力。"
        imageSize={6}
      />
      <ImageText
        position="left"
        bgColor="yellow"
        image={Feature2}
        title="全渠道客户交互，重新定义CRM"
        description="是时候从单纯记录客户信息的CRM，升级到下一代的客户交互管理系统了，通过各种数字化的触点和用户建立直接的联系，并用各种内容和交互方式，逐步了解TA，用有价值有温度的长期沟通，建立属于企业自己的流量池。"
        imageSize={6}
      />
      <ImageText
        position="right"
        bgColor="grey"
        image={Feature3}
        title="搭建敏捷的营销体系"
        description="上线营销活动其实就是一个响指的时间，小到一次线下活动，大到搭建一个企业增长体系，推进企业的整体数字化转型的各种场景，你都可以立即开始尝试，让原本无人照看的客户得到无微不至的照顾，让重要客户的跟进变得高效和即时迅速验证效果，不断迭代策略，小步快跑不断增长。"
        imageSize={6}
      />
      <ImageText
        position="left"
        bgColor="yellow"
        image={Feature4}
        title="建立高效的销售漏斗"
        description="无论直接面向大客户，还是透过渠道，帮企业建立起市场团队和销售团队之间的高效协同。根据业务规则，自动将MQL分配到销售手中，销售实时获得最新线索和客户行为轨迹，有效促进商机跟进效果，完成营销一体化协同。"
        imageSize={6}
      />
    </section>
  );
}

export default HomeFeatures;
