import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'gatsby';
import { useSearch } from '../utils/common';
import TitleWithBg from './TitleWithBg';
import VerticalBox from './VerticalBox';
import ToolMore from '../images/tool-more.svg';

const boxList = [
  {
    id: '1',
    title: '数据集成',
    description: '预集成触点数据追踪，开放API数据接入、清洗、存储',
    iconIndex: 1,
  },
  {
    id: '2',
    title: '客户画像',
    description: '360°客户画像管理系统，属性、标签、群组、行为事件',
    iconIndex: 2,
  },
  {
    id: '3',
    title: '线索管理',
    description: '线索自动打分、评级等能力，加快线索自动化处理',
    iconIndex: 3,
  },
  {
    id: '4',
    title: '数据分析',
    description: '从行业、渠道、内容、活动等不同维度进行分析和挖掘',
    iconIndex: 4,
  },
  {
    id: '5',
    title: '微信营销',
    description: '“公域+私域”流量打通，精细化运营微信私域流量',
    iconIndex: 5,
  },
  {
    id: '6',
    title: '内容管理',
    description: '文档、推广海报、落地页、H5页面、视频、直播等',
    iconIndex: 6,
  },
  {
    id: '7',
    title: '活动管理',
    description: '活动计划、执行和洞察，一体解决，快速上线',
    iconIndex: 7,
  },
  {
    id: '8',
    title: '企微助手',
    description: '企微私域管理工具，多渠道引流获客，精细化客户管理',
    iconIndex: 8,
    // letterSpacing: '-1px',
  },
  {
    id: '9',
    title: '互动触达',
    description: '微信、企微、短信、邮件、视频、直播等',
    iconIndex: 9,
  },
  {
    id: '10',
    title: '营销自动化',
    description: '自动化流程引擎，优化人、时间、内容、渠道组合',
    iconIndex: 10,
  },
  {
    id: '11',
    title: '销售协同',
    description: '根据业务需要自定义线索分配规则，并支持CRM无缝集成',
    iconIndex: 11,
  },
];

function HomeTool() {
  const search = useSearch();
  return (
    <section id="tool" className="home-tool">
      <Container>
        <TitleWithBg title="一体化B2B营销云" />
        <p className="tool-description">
          荟聚为你提供数据驱动运营所需的所有工具，构建全渠道客户增长体系，你想要的都有
        </p>
        <Row>
          {boxList.map((item) => (
            <Col key={item.id} lg={3} md={4} sm={6} xs={6} className="vertical-col">
              <VerticalBox {...item} />
            </Col>
          ))}
          <Col key="12" lg={3} md={4} sm={6} xs={6} className="vertical-col">
            <div className="vertical-box show-more">
              <Image src={ToolMore} alt="tool more" />
              <div className="bottom-link">
                <Link to={`/products/${search}`}>
                  <span>了解更多</span>
                  <div className="common-arrow-right" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HomeTool;
