import React from 'react';
import PropTypes from 'prop-types';

function ColoredIcon({ iconIndex = 1, hoverIconIndex, size = 100 }) {
  const columnIndex = Math.ceil(iconIndex / 15) - 1;
  const rowIndex = iconIndex - columnIndex * 15;
  const positionX = columnIndex * 100;
  const positionY = (rowIndex - 1) * 100;
  const xStr = positionX ? `-${positionX}px` : '0px';
  const yStr = positionY ? `-${positionY}px` : '0px';
  return (
    <div className={`colored-icon-wrapper size-${size}`}>
      <div className={`colored-icon ${hoverIconIndex ? `hover-${hoverIconIndex}` : ''}`} style={{ backgroundPosition: `${xStr} ${yStr}` }} />
    </div>
  );
}

ColoredIcon.propTypes = {
  iconIndex: PropTypes.number,
  hoverIconIndex: PropTypes.number,
  size: PropTypes.number,
};

export default ColoredIcon;
